import breakpoints from '@/helpers/breakpoints';
import { ref, computed } from 'vue';

const viewport = ref('');
const viewportHeight = ref('');
const isTouchDevice = computed(() => 'ontouchstart' in document.documentElement);
const isMobile = computed(() => ['xs', 'sm', 'md'].includes(viewport.value));

function toggleViewport() {
  const width = window.innerWidth;

  if (width >= breakpoints['xl-min']) {
    viewport.value = 'xl';
  } else if (width <= breakpoints['lg-max'] && width >= breakpoints['lg-min']) {
    viewport.value = 'lg';
  } else if (width <= breakpoints['md-max'] && width >= breakpoints['md-min']) {
    viewport.value = 'md';
  } else if (width <= breakpoints['sm-max'] && width >= breakpoints['sm-min']) {
    viewport.value = 'sm';
  } else {
    viewport.value = 'xs';
  }

  viewportHeight.value = window.innerHeight;
}

toggleViewport();
window.addEventListener('resize', toggleViewport);

export { viewport, viewportHeight, isTouchDevice, isMobile };
