import { createApp } from 'vue';
import { pinia } from './stores/pinia.js';
import App from './App.vue';
import { router } from './router';
import 'virtual:svg-icons-register';
import VueTablerIcons from 'vue-tabler-icons';
import { VueQueryPlugin } from '@tanstack/vue-query';

import 'element-plus/dist/index.css';
import './index.css';
import 'devextreme/dist/css/dx.light.css';
import { loading } from './helpers/loading';
import queryClient from './composables/queryClient';
import { ElSwitch, ElAutocomplete, ElRadioGroup, ElRadio, ElDrawer, ElDialog, ElButton, ElTable, ElPopover, ElTooltip, ElForm, ElInput, ElDatePicker, ElSelect, ElOption, ElCheckbox, ElUpload, ElIcon, ElAvatar, ElProgress, ElBadge } from 'element-plus';

const app = createApp(App);
app.use(ElSwitch);
app.use(ElAutocomplete);
app.use(ElRadioGroup);
app.use(ElRadio);
app.use(ElDrawer);
app.use(ElDialog);
app.use(ElButton);
app.use(ElTable);
app.use(ElPopover);
app.use(ElTooltip);
app.use(ElInput);
app.use(ElDatePicker);
app.use(ElSelect);
app.use(ElOption);
app.use(ElForm);
app.use(ElCheckbox);
app.use(ElUpload);
app.use(ElIcon);
app.use(ElAvatar);
app.use(ElProgress);
app.use(ElBadge);
app.use(VueTablerIcons);
app.directive('loading', loading);
app.use(VueQueryPlugin, {
  queryClient,
  enableDevtoolsV6Plugin: true,
});
app.use(router).use(pinia);
app.mount('#app');
