import { defineStore } from 'pinia';
import { getAllCompanies, getOnlineUsers } from '../api';
import { requestErrorHandler } from '../helpers/requestErrorHandler';
import { useStorage } from '@vueuse/core';

const showTestUsers = useStorage('showTestUsers', false);

export const useCommonStore = defineStore({
  id: 'common',
  state: () => ({
    pageTitle: '',
    pageSubTitle: '',
    online: [],
    companies: [],
  }),
  actions: {
    async getOnlineUsers() {
      try {
        const data = await getOnlineUsers();
        this.online = data;
      } catch (error) {
        return requestErrorHandler(error);
      }
    },
    async fetchCompanies() {
      try {
        const companies = await getAllCompanies();
        if (!showTestUsers.value) {
          this.companies = companies.filter((el) => !el.user_id.user_type);
        } else {
          this.companies = companies;
        }
      } catch (error) {
        return requestErrorHandler(error);
      }
    },
  },
});
